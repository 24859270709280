import "@elements/dom-utils/polyfills/ie";
import {onFind} from "@elements/init-modules-in-scope";
import {addClass, findAllIn, on} from '@elements/dom-utils';

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});

import 'bootstrap/js/dist/collapse';

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as skipLinks from '@elements/skip-links';
skipLinks.init();

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as nav from './nav';
nav.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as hashCash from './hashCash';
hashCash.init();

// if you use this, also use the associated CSS (content-visibility.scss)
import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as infoModal from './info-modal';
infoModal.init();

import * as tracking from "@elements/tracking";
let trackingOptions = {}; // optional options
tracking.initGtmEventTracking(trackingOptions); // for GTM tracking

import * as navTracking from './tracking/nav-tracking';
navTracking.init();

import * as consentOverlay from '@elements/consent-overlay';
import {createContentVisibility} from "./content-visibility";
consentOverlay.init({
        cmpTool: 'cookiebot',
        revalidateEvent: 'CookiebotOnConsentReady'
    },
    {
        base: '.js-consent',
        template: '.js-consent__template',
        dataset: 'consent',
        button: '.js-consent__accept-btn'
    });

onFind('.js-anchor-nav__list', function (element) {
    on('init.anchor-nav', function (e){
        let items = findAllIn('.anchor-nav__item', element);

        let totalWidth = 0;
        items.forEach(function (item) {
            totalWidth += item.offsetWidth;
        });

        if(totalWidth > element.parentElement.offsetWidth){
            addClass('is-overflow',element);
        }
    }, element);

});