import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, toggleClass, hasClass, on, closest, find, findIn, findAll, is} from '@elements/dom-utils';


const selectors = {
    list: '.js-nav__list',
    listItem: '.js-nav__list-item',
    listLink: '.js-nav__list-link',
    listHasSubnav: '.js-nav__has-subnav',
    menuToggle: '.js-nav__menu-toggle',
    navOverlay: '.js-nav__overlay',
    navBack: '.js-nav__back',
    menuaim: '.js-nav__menu-aim',
    subnav: '.js-nav__subnav',
    searchToggle: '.js-nav__search-toggle',
};

export function init() {
    /* For hovering and displaying the subnav level 2 on desktop */
    if(matchMedia('(min-width: 768px)').matches) {

        let timeout = 150;
        let timer;

        onFind(selectors.menuaim, function (item) {
            let items = findAll(selectors.menuaim);

            on('mouseover', (evt) => {
                clearTimeout(timer);

                timer = setTimeout(function() {
                    items.forEach((navItem) => {
                        removeClass('is-open', navItem);
                    });

                    addClass('is-open',item)

                    if(hasClass('js-nav__has-subnav', item)) {
                        let parentList = closest(selectors.list, evt.target);
                        addClass('subnav-is-active', parentList);
                    }

                }, timeout);

            }, item);

            on('mouseout', (evt) => {
                clearTimeout(timer);
                timer = setTimeout(function() {
                    removeClass('is-open',item);

                    if(hasClass(selectors.listHasSubnav, item)) {
                        let parentList = closest(selectors.list, evt.target);
                        removeClass('subnav-is-active', parentList);
                    }
                }, timeout);

            }, item);
        });
        onFind(selectors.listHasSubnav, function (item) {
            on('mouseout', (evt) => {
                clearTimeout(timer);
                timer = setTimeout(function() {
                    removeClass('is-open',item);

                        let parentList = closest(selectors.list, evt.target);
                        removeClass('subnav-is-active', parentList);
                }, timeout);

            }, item);
        });

        onFind(selectors.subnav, function (item) {
            on('mouseout', (evt) => {
                let parent = closest(selectors.subnav, evt.target);

                if (hasClass('js-nav__subnav', evt.target) && is(selectors.subnav, parent)) {
                    if (!closest(selectors.subnav, evt.target) || is(selectors.subnav, evt.target)) {
                        let list = findIn(selectors.list, evt.target);
                        removeClass('subnav-is-active', list);
                    }
                    let items = findAll(selectors.menuaim);

                    items.forEach((navItem) => {
                        removeClass('is-open', navItem);
                    });
                }

            }, item);
        });
    }


    /* When clicking mobile on the burger menu */
    if(matchMedia('(max-width: 767px)').matches) {
        onFind(selectors.menuToggle, function (element) {
            on('click', (evt) => {
                let mainNav = find('.js-navbar-main');
                toggleClass('navbar-main--open', mainNav);
                if(hasClass('navbar-main--open', mainNav)){
                    let allOpen = findAll('.is-open');
                    allOpen.forEach((item) => {
                        removeClass('is-open', item);
                    });
                }
                let menuTarget = evt.target.getAttribute('data-menu-overlay-target');
                toggleClass('is-open',find(menuTarget));
                toggleClass('nav-open',find('body'));
            }, element);
        });
    }


    /* When clicking mobile on a subnav-item toggle subnav */
    if(matchMedia('(max-width: 767px)').matches) {

        onFind(selectors.listLink, function (element) {
            on('click', (evt) => {
                if(hasClass('has-subnav', element.parentElement) || hasClass('js-nav__has-subnav', element.parentElement)) {
                    evt.preventDefault();
                    let parentLi = closest('li', evt.target);
                    addClass('is-open', parentLi);
                }
            }, element);
        });
    }


    /* When clicking mobile on the back button */
    if(matchMedia('(max-width: 767px)').matches) {
        onFind(selectors.navBack, function (element) {
            on('click', (evt) => {
                evt.preventDefault();

                let parentLi = closest('li', evt.target);
                removeClass('is-open', parentLi);

            }, element);
        });
    }

    /* mobile Search */

    if(matchMedia('(max-width: 767px)').matches) {
        onFind(selectors.searchToggle, function (element) {
            on('click', (evt) => {
                let searchTarget = find('.js-nav-search');
                toggleClass('is-open', searchTarget);
                toggleClass('search-open', element);
            }, element);
        });
    }

    if(matchMedia('(min-width: 768px)').matches) {
        onFind('.js-dropdown--lang', function (element) {
            removeClass('dropup', element);
            addClass('dropdown', element);
        });
    }
}
