"use strict";
import {on, findAllIn, hasClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-nav-tracking__link', (navLink) =>{
        on('click', (e)=>{
            console.log('click', navLink.getAttribute('href'));
                let navLinkUrl = navLink.getAttribute('href').split("/");
            console.log('navLinkUrl', navLinkUrl);
            window.dataLayer.push({
                'event': 'click_navigation',
                'main_item': navLinkUrl[3],
                'sub_item_one': navLinkUrl[4],
                'sub_item_two': navLinkUrl[5],
                'click_item': navLink.innerText,
                'link_url': document.location.origin + navLink.getAttribute('href'),
            });
        },navLink);
    });
}